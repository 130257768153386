/**************************      Components       ***************************/

/* Button */

.MuiOutlinedInput-notchedOutline {
  text-align: left;
  position: absolute;
  bottom: 0;
  right: 0;
  top: -5px;
  left: 0;
  margin: 0;
  padding: 0 8px;
  pointer-events: none;
  overflow: hidden;
  min-width: 0%;
  border: 1px solid #767676 !important;
  border-radius: 3px !important;
}

.MuiInputBase-root-MuiOutlinedInput-root {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  font-size: 0.8rem !important;
}

.MuiInputBase-input-MuiOutlinedInput-input {
  font-size: 0.8rem !important;
  color: black !important;
}

.MuiAutocomplete-root .MuiOutlinedInput-root {
  padding: 0px !important;
  margin-top: 5px;
  min-height: 42px;
}

button:hover {
  opacity: 0.75;
}

button {
  opacity: 1;
}

/* Ion Icon */
ion-icon {
  @apply text-xl text-textdark opacity-90 mr-1.5;
}

::-webkit-scrollbar {
  width: 10px;
}
.legaltech ::-webkit-scrollbar-thumb {
  @apply bg-base bg-opacity-50;
  border-radius: 5px;
}
.ilta ::-webkit-scrollbar-thumb {
  @apply bg-lthblue bg-opacity-50;
  border-radius: 5px;
}

.yr {
  width: 210px;
  color: #011d58;
  font-family: Poppins;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 30px;
}

.rectangle {
  box-sizing: border-box;
  height: 42px;
  border: 1px solid #767676;
  border-radius: 3px;
  background-color: rgba(255, 255, 255, 0.9);
  margin-top: 5px;
}

.rectangle-err {
  box-sizing: border-box;
  height: 42px;
  border: 1px solid #e11d48;
  border-radius: 3px;
  background-color: rgba(255, 255, 255, 0.9);
  margin-top: 5px;
}

.rectangle-textarea {
  box-sizing: border-box;
  height: 100px;
  border: 1px solid #767676;
  border-radius: 3px;
  background-color: rgba(255, 255, 255, 0.9);
  margin-top: 5px;
}

.rmsc .gray {
  color: black !important;
}

.resource-type-text {
  height: 18px;
  color: #333333;
  font-family: Karla;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0.58px;
  line-height: 18px;
}

.resource-desc {
  color: #333333;
  font-family: Karla;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 15px;
}

.resource-normal-link {
  color: #011d58;
  font-family: Karla;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 15px;
}

.request-header-desc {
  color: #333333;
  font-family: Karla;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 22px;
}

.request-contact-container {
  box-sizing: border-box;
  border: 1px solid #dddddd;
  border-radius: 5px;
  background-color: #ffffff;
}

.request-contact-header-container {
  height: 76.96px;
  background-color: #69f3b6;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.request-contact-header {
  height: 77px;
  color: #333333;
  font-family: Poppins;
  font-size: 17px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 26px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.request-field-title {
  color: #333333;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 21px;
}

.request-field-sub-title {
  height: 20px;
  color: #333333;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 21px;
}

.request-contact-line {
  box-sizing: border-box;
  height: 1px;
  border: 1px solid #29ef97;
}

.request-contact-divide-text {
  height: 48px;
  color: #333333;
  font-family: Karla;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0.5px;
  line-height: 18px;
  text-align: center;
}

.request-contact-section-text {
  height: 48px;
  color: #333333;
  font-family: Karla;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0.5px;
  line-height: 18px;
  text-align: left;
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 10px;
  overflow: hidden;
  width: 238px;
  height: 156px;
}

.policy-container h2 {
  font-family: Poppins;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 19px;
  padding-top: 16px;
  padding-bottom: 16px;
}

.policy-container h4 {
  font-family: Poppins;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 19px;
  padding-top: 16px;
  padding-bottom: 20px;
}

.terms-container h2 {
  font-family: Poppins;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 19px;
  padding-top: 16px;
  padding-bottom: 16px;
}

.terms-container h4 {
  font-family: Poppins;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 19px;
  padding-top: 16px;
  padding-bottom: 20px;
}

.showmore-text {
  height: 60px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  color: #333333;
  font-family: Karla;
  font-size: 13px;
}

.rmsc .dropdown-container {
  position: relative;
  outline: 0;
  background-color: rgba(255, 255, 255, 0.9) !important;
  border: 1px solid #767676 !important;
  border-radius: 3px !important;
  height: 42px !important;
  margin-top: 5px !important;
}

.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
}

.avatar-container {
  display: flex;
  flex-direction: row;
  position: relative;
  height: 42px;
}

.avatar-container {
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* margin-top: 45px;
  margin-bottom: 25px; */
}

.avatar-container input[type='file'] {
  color: transparent;
}

.blog-content {
  color: #333333 !important;
  font-family: Poppins;
}

/* .blog-content > h1 {
  font-family: Poppins;
  font-weight: bold;
  font-size: 1.375rem !important;
}

.blog-content h2 {
  font-family: Poppins;
  font-weight: bold;
  font-size: 1.25rem !important;
}

.blog-content h3 {
  font-family: Poppins;
  font-weight: bold;
  font-size: 1.125rem !important;
  /* background-color: #29ef97;
  border-radius: 20px;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 17px;
  padding-bottom: 17px;
} */

.blog-content a,
.blog-content a > span {
  color: #011d58 !important;
  text-decoration: underline !important;
}

.search-text::placeholder {
  color: #011d58;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 500ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.i-have-read-and-acce {
  height: 27px;
  color: #011d58;
  font-family: Karla;
  font-size: 15px;
  letter-spacing: 0;
  line-height: 18px;
  padding-top: 3px;
}

.MuiInputBase-input.MuiAutocomplete-input {
  font-size: 15px;
  font-family: 'Karla';
}

.MuiAutocomplete-listbox {
  font-size: 15px;
  font-family: 'Karla';
}

.ReactModal__Overlay {
  background-color: rgba(34, 37, 43, 0.75) !important;
}

.toastProgress {
  background: #333f48 !important;
}

.body-height {
  height: calc(100% - 51px);
}

button[disabled]:hover {
  opacity: 0.4;
}

button[disabled] {
  opacity: 0.4;
}

.text-password {
  -webkit-text-security: disc;
}

.html-show ul {
  list-style-type: initial;
}

.html-show li {
  margin-left: 2rem;
}

.html-show p {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

.html-show a {
  @apply text-base;
}

.html-show img {
  margin: 0 auto;
}

.css-iljtu2-MuiRating-root {
  color: #011d58 !important;
  font-size: 22px !important;
}

.css-ryrseu-MuiRating-root {
  color: #011d58 !important;
  font-size: 22px !important;
}

.css-1algrk9-MuiRating-root {
  font-size: 14px !important;
  color: #011d58 !important;
}

.css-1lauo1g-MuiRating-root {
  font-size: 14px !important;
  color: #011d58 !important;
}

.rbd-m6ukimpwaps-h {
  background: #e3e3e3;
  height: 5px;
  width: 0px;
  border-radius: 5px;
}

.Toastify__toast {
  margin-bottom: 10px !important;
  min-height: 50px !important;
}

.Toastify__toast-container {
  width: auto !important;
}

@media screen and (max-width: 480px) {
  .Toastify__toast-container {
    width: 100vw !important;
  }
}

.Toastify__toast-theme--colored.Toastify__toast--success {
  background: #54f2ac !important;
}

.Toastify__close-button {
  color: #011d58 !important;
}

.toast-confirm-title {
  color: #011d58;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
}

.toast-confirm-view-all {
  color: #011d58;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 20px;
  text-align: right;
  text-decoration: underline !important;
}

.body-des-html > p {
  color: #333333;
  font-family: var(--font-tertiary);
  font-size: 15px;
  letter-spacing: 0;
  line-height: 18px;
}

.header-des-html > p {
  color: #333333;
  font-family: Karla;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 22px;
}

.box {
  border: 1px solid black;
}

.min-h-full {
  min-height: -webkit-fill-available;
}

.terms-content > h2,
.terms-content > p,
.terms-content > ul,
.terms-content > span {
  font-family: karla !important;
  text-color: #333333 !important;
}

.terms-content > p > span {
  font-family: karla !important;
  text-color: #333333 !important;
}

.terms-content > h2 {
  font-size: 1.375rem !important;
  line-height: 30px;
  margin-top: 10px;
}

#longDesc > p,
#longDesc > span {
  color: transparent !important;
}

#longDesc > p > span {
  color: transparent !important;
}

#longDesc > p > p {
  color: transparent !important;
}

.text-line-2 {
  display: block; /* or inline-block */
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  max-height: 3em;
  line-height: 1.4em;
}

.expert {
  object-fit: cover !important;
  width: 120px;
  height: 120px;
  border-radius: 60px;
}

.slick-slider {
  height: 100%;
}

.react-datepicker-wrapper input[type='text'] {
  width: 100%;
}

.react-datepicker-wrapper input[type='text'] {
  outline: none;
  font-size: 15px;
  font-family: 'karla';
  font-weight: 500;
  color: rgb(51 51 51 / var(--tw-text-opacity));
  --tw-text-opacity: 1;
}

.drawer {
  height: 210px !important;
  background: transparent !important;
  align-items: center;
  display: flex;
  justify-content: center;
}

.drawer__backdrop {
  height: 0px !important;
}

.drawer__handle-wrapper {
  width: 0px !important;
}

.drawer__handle {
  background: transparent !important;
  width: 0px !important;
}

.drawer__content {
  width: 800px !important;
  height: 210px !important;
  padding: 0px !important;
}

input {
  outline: none;
}

.tox .tox-editor-header {
  z-index: 0 !important;
}

.css-1p3m7a8-multiValue{
  background-color: #d4d4fc;
}

.arrow{
  position: relative;
  top: -26px;
  left: 97%;
  cursor: pointer;
}
.arrow-head{
  position: relative;
  top: -31px;
  left: 94%;
  cursor: pointer;
}

.adjust{
  position: relative;
  top: -24px;
}

.mb{
  margin-bottom: 6px;
}

.custom-bullet ul {
  @apply list-none pl-5; /* Remove default bullets and add padding */
}

.custom-bullet li {
  @apply relative pl-6; /* Ensure bullets are positioned correctly */
}

.custom-bullet li::before {
  content: '•'; /* Custom bullet character */
  @apply absolute left-0 top-2/4 -translate-y-[30%] !text-2xl text-[#333333]; /* Custom bullet color and size */
  font-size: 1.2em; /* Custom bullet size */
  line-height: 1; /* Ensure alignment with text */
}

/* div.html-show > p {
  display: -webkit-box;       // Required for the text truncation to work
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 5;       // Limits the text to 5 lines
  line-height: 1.2em;          // Adjusts line height
  max-height: calc(1.2em * 5); // Ensures max height for 5 lines
} */

.text-darkgray .HtmlContentViewer_ckContent__8S_dO > p {
  text-align: justify
}

.homeGuidBoxHeading {
  color: #333333 !important;
  font-family: Poppins !important;
  font-size: 24px !important;
  font-weight: bold !important;
  letter-spacing: 0 !important;
  line-height: 26px !important;
}
.homeGuidBoxDescription {
  font-size: 16px !important;
  letter-spacing: 0 !important;
  line-height: 19px !important;
  letter: normal !important;
  color: #767676 !important;
}

.threeBox a span.flex.flex-row{
  width:333px;
  height:180px;
  padding-right:16px !important
}

/* css for print */
.cookieConsentPoup, .topHeaderMenu {
  display: black;
}
@media print {
  .mainContainerPrint {
    overflow: visible !important;
  }
  .mainContainerPrint .page-break {
    page-break-before: always;
  }

  .mainContainerPrint .flex,
  .mainContainerPrint .flex-col,
  .mainContainerPrint .flex-row {
    display: block !important;
  }

  .mainContainerPrint h2 {
    page-break-before: always;
  }

  .mainContainerPrint .text-[1.5rem] {
    font-size: 1rem !important;
  }
  .mainContainerPrint .md\:text-[24px] {
    font-size: 1.2rem !important;
  }
  .mainContainerPrint .w-full {
    width: 100% !important;
  }

  .mainContainerPrint .cookieConsentPoup,
  .mainContainerPrint .topHeaderMenu {
    display: none !important;
    visibility: hidden !important;
  }

  .mainContainerPrint .tdBody {
    margin-bottom: 100px;
    border: 2px solid transparent;
  }
  .mainContainerPrint .socialIcon,
  .mainContainerPrint .searchLegaltechJobs {
    display: flex !important;
  }
  .mainContainerPrint .searchLegaltechJobs {
    margin-top: 20px !important;
    padding-top: 50px;
  }
  .mainContainerPrint .print-flex {
    display: flex !important;
  }

  .mainContainerPrint .about-auther img {
    height: 100%;
    width: 100%;
    object-fit: content;
  }
  .mainContainerPrint .about-auther-text {
    width: 80%;
    flex: 0 0 80%;
  }
  .mainContainerPrint .header-des-html {
    margin-top: 0 !important;
  }
  .mainContainerPrint .blog-content {
    margin-bottom: 0 !important;
  }

  .mainContainerPrint .about-auther-text p {
    line-height: 1 !important;
    font-size: 18px !important;
  }
  .mainContainerPrint .materialSectionPrint {
    min-height: unset !important;
  }
}

.solutions-col-1 .col-width{
  flex:0 0 100%
}

.solutions-col-2 .col-width{
  flex:0 0 50%
}

.solutions-col-3 .col-width{
  flex:0 0 33.33%
}

.solutions-col-4 .col-width{
  flex:0 0 25%
}

.solutions-col-5 .col-width{
  flex:0 0 20%
}

.announcement-rectangle {
  border-radius: 3px !important;
  background-color: rgba(41,239,151,0.3) !important;
  padding: 15px !important;
}
.announcement-rectangle .bellShape {
  height: auto !important;
  /* width: 17.16px !important; */
}

.announcement-rectangle .announcement-versio, .announcement-rectangle p {
  color: #333333 !important;
  font-family: Karla !important;
  font-size: 16px !important;
  letter-spacing: 0 !important;
  line-height: 22px !important;
  font-weight: 400;
}
.announcement-rectangle .published {
  color: #333333;
  font-family: Karla;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 22px;
}

.announcement-rectangle svg {  
  width: 24px !important;  
  height: 27px !important; /* or a specific value like '150px' */  
}
